import { useRouter } from "next/router"
import ItemMedium from "../base/list/ItemMedium"
import Card from "./base/Card"

function MyTradeOffers({ buy, sell }) {
  const router = useRouter()
  const onItemClick = (slug) => {
    router.push(`/items/${slug}`)
  }

  return (
    <Card title={`My Trade Offers (${(buy.count || 0) + (sell.count || 0)})`}>
      <>
        <small className="text-white text-xs uppercase pb-1">
          My Sell Listings ({sell.count || 0})
        </small>
        {sell &&
          sell.rows &&
          sell.rows.map((row) => (
            <ItemMedium
              key={`MyTradeOffers-Sell-${row.id}`}
              onClick={() => {
                onItemClick(row.Item.slug)
              }}
              columns={[
                {
                  type: "item-image",
                  item: row.Item
                },
                { type: "text", value: row.Item.name },
                { type: "spacer" },
                { type: "text", value: `$${row.price}` }
              ]}
            />
          ))}

        <small className="text-white text-xs uppercase block pt-3 pb-1">
          My Buy Orders ({buy.count || 0})
        </small>
        {buy &&
          buy.rows &&
          buy.rows.map((row) => (
            <ItemMedium
              key={`MyTradeOffers-Buy-${row.id}`}
              onClick={() => {
                onItemClick(row.Item.slug)
              }}
              columns={[
                {
                  type: "item-image",
                  item: row.Item
                },
                { type: "text", value: row.Item.name },
                { type: "spacer" },
                { type: "text", value: `$${row.price}` }
              ]}
            />
          ))}
      </>
    </Card>
  )
}

export default MyTradeOffers
