function Card({ title, children, className, highlight }) {
  return (
    <div
      className={`w-full bg-black bg-opacity-20 mb-3 ${className} ${
        highlight ? "bg-gray-300" : ""
      }`}
    >
      {title && (
        <h2 className="w-full bg-black bg-opacity-25 uppercase text-white text-2xl leading-6 p-4 font-tahoma">
          {title}
        </h2>
      )}
      <div className="p-3">{children}</div>
    </div>
  )
}

export default Card
