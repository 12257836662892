import Image from "next/image"
import ItemImage from "../ItemImage"

function ItemMedium({ columns, onClick }) {
  return (
    <div
      className={`flex flex-row gap-3 items-center bg-black bg-opacity-20 pr-4 mb-1 ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      {columns?.map((column, index) => {
        if (column.type === "image") {
          return (
            <Image
              key={`ItemMedium-image-${column.value}`}
              src={column.value}
              alt={column.alt}
              width={86}
              height={46}
            />
          )
        } else if (column.type === "spacer") {
          return (
            <span
              key={`ItemMedium-spacer-${index}`}
              className="flex-grow"
            ></span>
          )
        } else if (column.type === "item-image") {
          return (
            <ItemImage
              key={`ItemMedium-item-image-${column.value}`}
              item={column.item}
              type="medium"
            />
          )
        }

        return (
          <p key={`ItemMedium-text-${index}`} className="text-white text-xs">
            {column.value}
          </p>
        )
      })}
    </div>
  )
}

export default ItemMedium
