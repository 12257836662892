import { useStore } from "lib/zustandProvider"
import Image from "next/image"
import Link from "next/link"

function SortNavigation({ activeSortOrder, onSortOrderChange }) {
  const user = useStore((state) => state.user)
  const classesActive = "opacity-100 text-sky-500"
  const classesInactive = "opacity-40 hover:text-sky-500"
  return (
    <div className="flex flex-row gap-8 bg-black bg-opacity-30 px-8 py-3 justify-center items-center uppercase text-base text-white">
      <Link
        href="?order=popular"
        className={
          activeSortOrder === "popular" ? classesActive : classesInactive
        }
        onClick={(e) => {
          e.preventDefault()
          onSortOrderChange("popular")
        }}
      >
        Popular Items
      </Link>
      <Link
        href="?order=new"
        className={activeSortOrder === "new" ? classesActive : classesInactive}
        onClick={(e) => {
          e.preventDefault()
          onSortOrderChange("new")
        }}
      >
        Newly Listed
      </Link>
      <Link
        href="?order=recent"
        className={
          activeSortOrder === "recent" ? classesActive : classesInactive
        }
        onClick={(e) => {
          e.preventDefault()
          onSortOrderChange("recent")
        }}
      >
        Recently Sold
      </Link>
      {user.authenticated && (
        <Link
          href="?order=wishlist"
          className={
            activeSortOrder === "wishlist" ? classesActive : classesInactive
          }
          onClick={(e) => {
            e.preventDefault()
            onSortOrderChange("wishlist")
          }}
        >
          *WISHLIST (54)
        </Link>
      )}
      <span className="flex-grow"></span>
      <div className="flex flex-row gap-2 justify-center items-center">
        <Link href="?sort=grid">
          <Image src="/icons/grid.svg" width={18} height={18} alt="Grid" />
        </Link>
        <Link href="?sort=list">
          <Image src="/icons/list.svg" width={18} height={18} alt="Grid" />
        </Link>
      </div>
    </div>
  )
}

export default SortNavigation
