import { useStore } from "lib/zustandProvider"
import Image from "next/image"
import { useRouter } from "next/router"
import { useState, useMemo, useEffect } from "react"
import { listItems } from "services/items"
import ItemImage from "../base/ItemImage"
import Table from "./base/Table"

function truncateText(text, length) {
  if (text.length <= length) {
    return text
  }

  return text.substr(0, length) + "\u2026"
}

function ItemsTable({
  initialItems,
  initialPage,
  sortOrder,
  search,
  showPagination,
  onPaginationChange: parentOnPaginationChange,
  rowClassName,
  className
}) {
  const router = useRouter()
  const token = useStore((state) => state.token)
  const wikiMode = useStore((state) => state.wikiMode)
  const [items, setItems] = useState(initialItems)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(initialPage)

  const updateTable = async () => {
    setLoading(true)
    const newItems = await listItems(token, {
      order: sortOrder,
      page,
      search
    })
    setItems(newItems)
    setLoading(false)
  }

  const onPaginationChange = async (page) => {
    setPage(page)
    parentOnPaginationChange(page)
  }

  useEffect(() => {
    if (items.query.page !== router.query.page) {
      setPage(parseInt(router.query.page || 0))
    }
  }, [router.query])

  useEffect(() => {
    if (items.query.order !== sortOrder || items.query.search !== search) {
      setPage(0)
    }
    if (
      items.query.order !== sortOrder ||
      items.query.page !== page ||
      items.query.search !== search
    ) {
      updateTable()
    }
  }, [sortOrder, page, search])

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "image",
        width: 600,
        Cell: (tableProps) => (
          <div className="flex flex-row items-center gap-3">
            <ItemImage item={tableProps.row.original.item} />
            {search && (
              <div className="flex flex-col leading-4 lg:leading-7">
                <p className="text-xs lg:text-lg">
                  {tableProps.row.original.item.tier}{" "}
                  {tableProps.row.original.name}
                </p>
                <small className="block leading-3 lg:leading-7">
                  {tableProps.row.original.item.skin}
                </small>
              </div>
            )}
            {!search && <p>{tableProps.row.original.name}</p>}
          </div>
        )
      },
      ...(wikiMode
        ? []
        : [
            {
              Header: "Qty",
              accessor: "qty",
              title: "Quantity",
              width: 200,
              Cell: (tableProps) => (
                <div className="flex flex-row items-center justify-center w-full h-full min-w-55px lg:min-w-0">
                  <p>{tableProps.row.original.qty}</p>
                </div>
              )
            },
            {
              Header: "Price",
              accessor: "price",
              width: 100,
              Cell: (tableProps) => (
                <div className="flex flex-col items-center justify-center w-full h-full min-w-55px lg:min-w-0">
                  <span className="text-2sm lg:text-xs opacity-40">
                    Starting at:
                  </span>
                  <p>{tableProps.row.original.price}</p>
                </div>
              )
            }
          ])
    ]
  }, [items])

  const data = useMemo(() => {
    return items.items.map((item) => {
      return {
        item,
        slug: item.slug,
        name: item.name,
        qty: item.listings,
        price: item.minPrice ? `$${item.minPrice.toFixed(2)}` : "-"
        // mobileTitle: search ? `${item.tier} ${item.name} - ${item.skin}` : null
      }
    })
  }, [items])

  const onRowClick = (rowData) => {
    if (!search) {
      window.location = `/?search=${rowData.name}`
    } else {
      router.push(`/items/${rowData.slug}`)
    }
  }

  return (
    <>
      {loading ? (
        <span>Loading...</span>
      ) : (
        <Table
          className={className}
          columns={columns}
          data={data}
          onRowClick={onRowClick}
          rowClassName={rowClassName}
          onPaginationChange={onPaginationChange}
          currentPage={page}
          pages={
            showPagination ? Math.ceil(items.count / items.query.perPage) : 1
          }
        />
      )}
    </>
  )
}

export default ItemsTable
