import ItemSmall from "../base/list/ItemSmall"
import Card from "./base/Card"

function RecentActivity({ activity }) {
  return (
    <Card title="Recent Activity">
      {activity &&
        activity.map((row, index) => (
          <ItemSmall
            key={`RecentActivity-${row.User.username}-${row.description}-${index}`}
            columns={[
              {
                type: "image",
                value: `${
                  row.User.image || process.env.NEXT_PUBLIC_DEFAULT_USER_IMAGE
                }`,
                alt: "User Icon"
              },
              { type: "text", value: `${row.User.username} ${row.description}` }
            ]}
          />
        ))}
    </Card>
  )
}

export default RecentActivity
