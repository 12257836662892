import { useState } from "react"

export const useInputState = (initialValue, checkbox) => {
  const [value, setValue] = useState(
    initialValue === undefined || initialValue === null ? "" : initialValue
  )
  const props = {
    onChange: (e) => {
      if (checkbox) {
        setValue(e.target.checked)
      } else {
        setValue(e.target.value)
      }
    }
  }

  if (checkbox) {
    props.checked = value
  } else {
    props.value = value
  }

  return [value, props, setValue]
}
