import { useMemo } from "react"
import { useTable } from "react-table"

const getPageIndexOptions = function (
  lastPageIndex,
  maxNumberOfOptions,
  pageIndex
) {
  let options = []
  const pivot = Math.ceil(maxNumberOfOptions / 2)

  if (lastPageIndex <= maxNumberOfOptions) {
    while (options.length < lastPageIndex) options.push(options.length)
  } else if (pageIndex < pivot) {
    while (options.length < maxNumberOfOptions) options.push(options.length)
  } else if (pageIndex > lastPageIndex - pivot) {
    while (options.length < maxNumberOfOptions)
      options.unshift(lastPageIndex - options.length)
  } else {
    for (
      var i = pageIndex - (pivot - 1);
      options.length < maxNumberOfOptions;
      i++
    ) {
      options.push(i)
    }
  }

  if (options[0] == 1) {
    options = [0, ...options]
  } else if (options[0] > 1) {
    options = [0, "...", ...options]
  }

  if (options[options.length - 1] == lastPageIndex - 1) {
    options = [...options, lastPageIndex]
  } else if (options[options.length - 1] < lastPageIndex - 1) {
    options = [...options, "...", lastPageIndex]
  }

  return options
}

function Table({
  columns,
  data,
  onRowClick,
  rowClassName,
  onPaginationChange,
  currentPage,
  pages,
  className,
  highlightBorderAfter
}) {
  const tableInstance = useTable({ columns, data })

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  const pageArray = useMemo(() => {
    return getPageIndexOptions(pages - 1, 10, currentPage)
  }, [pages, currentPage])

  return (
    <>
      <table {...getTableProps()} className={`w-full ${className}`}>
        <thead className="bg-black opacity-40 text-white uppercase text-sm">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps()}
                  title={column.title ? column.title : null}
                  className={index > 0 ? "text-center" : "text-left pl-5"}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <>
                {row.original.mobileTitle && (
                  <tr
                    key={`Table_mobile_title_row_${index}`}
                    className={`bg-black bg-opacity-20 border-uh-background hover:bg-opacity-40 lg:hidden ${
                      onRowClick ? "cursor-pointer" : ""
                    }`}
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row.original)
                      }
                    }}
                  >
                    <td
                      colSpan={row.cells.length}
                      className="text-white text-sm px-2 pt-1 uppercase"
                    >
                      {row.original.mobileTitle}
                    </td>
                  </tr>
                )}
                <tr
                  {...row.getRowProps()}
                  className={`bg-black bg-opacity-20 border-uh-background hover:bg-opacity-40 ${
                    onRowClick ? "cursor-pointer" : ""
                  } ${
                    highlightBorderAfter === index ? "border-b-8" : "border-b-4"
                  }`}
                  onClick={() => {
                    if (onRowClick) {
                      onRowClick(row.original)
                    }
                  }}
                >
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${rowClassName} text-white text-sm lg:text-lg ${
                          index > 0 ? "text-center" : "text-left"
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
      <div className="flex flex-row gap-1 w-full justify-center mt-6">
        {pageArray.map((page) => {
          return page === "..." ? (
            <span key={`Table-Pagination-${page}`} className="text-white">
              ...
            </span>
          ) : (
            <button
              key={`Table-Pagination-${page}`}
              className={`w-5 h-5 text-xs text-white ${
                page === currentPage ? "bg-button-primary" : "bg-sky-600"
              }`}
              onClick={() => onPaginationChange(parseInt(page))}
            >
              {parseInt(page) + 1}
            </button>
          )
        })}
      </div>
    </>
  )
}

export default Table
