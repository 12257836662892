import { useRouter } from "next/router"
import ItemMedium from "../base/list/ItemMedium"
import Card from "./base/Card"

function NewlyListed({ items }) {
  const router = useRouter()
  const onItemClick = (slug) => {
    router.push(`/items/${slug}`)
  }

  return (
    <Card title="Newly Listed">
      {items.items.map((item) => (
        <ItemMedium
          key={`NewlyListed-${item.slug}`}
          onClick={() => {
            onItemClick(item.slug)
          }}
          columns={[
            {
              type: "item-image",
              item
            },
            { type: "text", value: item.name },
            { type: "spacer" },
            { type: "text", value: `$${item.minPrice}` }
          ]}
        />
      ))}
    </Card>
  )
}

export default NewlyListed
