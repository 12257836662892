import Image from "next/image"

const widths = {
  xl: "w-item-xl h-item-xl",
  large:
    "w-item-medium h-item-medium lg:w-item lg:h-item min-w-item-medium min-h-item-medium lg:min-w-item lg:min-h-item",
  medium: "w-item-medium h-item-medium",
  "medium-large": "w-item-medium-large h-item-medium-large",
  none: "",
  full: "w-full h-full"
}

function ItemImage({ item, type, className, hideBackground }) {
  const image = `/items/original/${item.image}`
  const background = `/items/backgrounds/frameless/${item.tier.toLowerCase()}.png`
  let style = {
    backgroundImage: `url(${background})`,
    backgroundSize: "fit",
    boxShadow: "inset 0 0 0 1px rgba(255, 255, 255, 0.5)"
  }

  if (hideBackground) {
    style = {}
  }
  const width = type ? widths[type] : widths["large"]
  return (
    <div className={`relative ${width} bg-cover ${className}`} style={style}>
      <Image
        src={image}
        width={130}
        height={70}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/4 h-3/4 object-contain"
        alt={`${item.name} Image`}
      />
    </div>
  )
}

export default ItemImage
