import Button from "@/components/form/base/Button"
import { useInputState } from "lib/input"

function Search({ onSearch, initialValue, showAdvancedFilters }) {
  const [searchText, searchProps] = useInputState(initialValue)

  return (
    <div className="px-1 lg:px-8 pt-3">
      <span className="text-white opacity-70 text-base">
        Looking for a specific item?
      </span>
      <div className="flex flex-row gap-2">
        <input
          className="input flex-grow"
          placeholder="Start typing an item name here to filter items"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch(searchText)
            }
          }}
          {...searchProps}
        />
        <Button
          onClick={(e) => {
            e.preventDefault()
            onSearch(searchText)
          }}
        >
          Search
        </Button>
        {showAdvancedFilters && (
          <Button className="ml-1 lg:ml-3" type="info">
            Show Advanced Filters
          </Button>
        )}
      </div>
    </div>
  )
}

export default Search
