import Image from "next/image"
import ItemImage from "../ItemImage"

function ItemSmall({ columns }) {
  return (
    <div className="flex flex-row gap-2 items-center pr-4 mb-1">
      {columns?.map((column, index) => {
        if (column.type === "image") {
          return (
            <Image
              key={`ItemSmall-image-${column.value}`}
              src={column.value}
              alt={column.alt}
              width={38}
              height={38}
              className="w-38px h-38px"
            />
          )
        } else if (column.type === "spacer") {
          return (
            <span
              key={`ItemSmall-spacer-${index}`}
              className="flex-grow"
            ></span>
          )
        } else if (column.type === "item-image") {
          return <ItemImage item={column.item} />
        }

        return (
          <p key={`ItemSmall-text-${index}`} className="text-white text-xs">
            {column.value}
          </p>
        )
      })}
    </div>
  )
}

export default ItemSmall
