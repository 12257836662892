import { request } from "lib/request"

export const listItems = async (token, props) => {
  const { order = "popular", page = 0, perPage = 12, search = "" } = props || {}
  return (
    await request("items", {
      token,
      query: {
        order,
        page,
        search,
        perPage
      }
    })
  ).data
}

export const listMyTradeOffers = async (token) => {
  return (await request("items/my-trade-offers", { token })).data
}

export const getItemMetaData = async (token, slug) => {
  return (await request(`items/${slug}`, { token })).data
}

export const getItemSalesData = async (token, slug) => {
  return (await request(`items/${slug}/indicators`, { token })).data
}

export const getItemSalesHistory = async (token, slug) => {
  return (await request(`items/${slug}/history`, { token })).data
}

export const getItemSellListings = async (token, slug, props) => {
  const { page = 0, perPage = 5 } = props || {}
  return (
    await request(`items/${slug}/sell-listings`, {
      token,
      query: { page, perPage }
    })
  ).data
}

export const getItemBuyStatistics = async (token, slug) => {
  return (await request(`items/${slug}/buy-statistics`, { token })).data
}

export const sellItem = async (token, slug, price) => {
  return (
    await request(`items/${slug}/sell`, {
      method: "POST",
      token,
      body: { price }
    })
  ).data
}
