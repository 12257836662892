import MyTradeOffers from "@/components/card/MyTradeOffers"
import NewlyListed from "@/components/card/NewlyListed"
import RecentActivity from "@/components/card/RecentActivity"
import Search from "@/components/layout/navigation/Search"
import SortNavigation from "@/components/layout/navigation/SortNavigation"
import ItemsTable from "@/components/table/ItemsTable"
import { useStore } from "lib/zustandProvider"
import { useRouter } from "next/router"
import { useState } from "react"
import { listRecentActivity } from "services/activity"
import { listItems, listMyTradeOffers } from "services/items"
import { pageMiddleware } from "services/page"

export const getServerSideProps = async (context) => {
  const pageData = await pageMiddleware(context, (token) => {
    return [
      listItems(token, context.query),
      listItems(token, { order: "new", page: 0, perPage: 7 }),
      listRecentActivity(token),
      token ? listMyTradeOffers(token) : null
    ]
  })

  if (pageData.redirect) {
    return pageData
  }

  const [
    token,
    store,
    { user },
    initialItems,
    newlyListedItems,
    recentActivity,
    myTradeOffers
  ] = pageData

  if (user) {
    store.getState().setUser(user)
  }

  return {
    props: {
      initialStoreState: JSON.stringify(store.getState()),
      initialItems,
      newlyListedItems,
      recentActivity,
      myTradeOffers,
      authenticated: token ? true : false,
      initialQuery: {
        ...context.query
      }
    }
  }
}

export default function Home({
  initialItems,
  newlyListedItems,
  recentActivity,
  myTradeOffers,
  authenticated,
  initialQuery
}) {
  const router = useRouter()
  const [order, setOrder] = useState(initialQuery.order || "popular")
  const [search, setSearch] = useState(initialQuery.search || "")
  const devMode = useStore((state) => state.devMode)

  const updateRouterQuery = (query) => {
    router.push(
      {
        query: { ...router.query, ...query }
      },
      undefined,
      { shallow: true }
    )
  }

  const onSortOrderChange = (newOrder) => {
    setOrder(newOrder)
    updateRouterQuery({ order: newOrder })
  }

  const onPaginationChange = (newPage) => {
    updateRouterQuery({ page: newPage })
  }

  const onSearch = (searchText) => {
    setSearch(searchText)
    updateRouterQuery({ search: searchText })
  }

  return (
    <div className="flex flex-col gap-4">
      <Search
        onSearch={onSearch}
        initialValue={search}
        showAdvancedFilters={devMode}
      />
      {devMode && (
        <SortNavigation
          activeSortOrder={order}
          onSortOrderChange={onSortOrderChange}
        />
      )}
      <div className="flex flex-row gap-6 px-1 lg:px-8 py-5">
        <main className="flex-grow">
          <ItemsTable
            initialItems={initialItems}
            initialPage={parseInt(initialQuery.page || 0)}
            sortOrder={order}
            search={search}
            showPagination={true}
            onPaginationChange={onPaginationChange}
            rowClassName="uppercase"
          />
        </main>
        {devMode && (
          <aside className="w-320px min-w-320px">
            {authenticated && (
              <MyTradeOffers
                buy={myTradeOffers.buyOffers}
                sell={myTradeOffers.sellOffers}
              />
            )}
            <NewlyListed items={newlyListedItems} />
            <RecentActivity activity={recentActivity.activity} />
          </aside>
        )}
      </div>
    </div>
  )
}
